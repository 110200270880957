@use 'reset';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import 'theme/main';

@import url('https://p.typekit.net/p.css?s=1&k=ygl3odl&ht=tk&f=35359.35360.35361.35362.35363.35364.35365.35366.35367.35368.35369.35370.35371.35372.35373.35374&a=33068725&app=typekit&e=css');

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/d2bf21/00000000000000003b9b32da/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d2bf21/00000000000000003b9b32da/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d2bf21/00000000000000003b9b32da/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/d5e645/00000000000000003b9b32db/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d5e645/00000000000000003b9b32db/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d5e645/00000000000000003b9b32db/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 100;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/29deea/00000000000000003b9b32dc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/29deea/00000000000000003b9b32dc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3')
      format('woff'),
    url('https://use.typekit.net/af/29deea/00000000000000003b9b32dc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/74824d/00000000000000003b9b32dd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/74824d/00000000000000003b9b32dd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3')
      format('woff'),
    url('https://use.typekit.net/af/74824d/00000000000000003b9b32dd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 200;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/96a85b/00000000000000003b9b32de/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/96a85b/00000000000000003b9b32de/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/96a85b/00000000000000003b9b32de/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/bb3a8a/00000000000000003b9b32df/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/bb3a8a/00000000000000003b9b32df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/bb3a8a/00000000000000003b9b32df/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/1cd062/00000000000000003b9b32e0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/1cd062/00000000000000003b9b32e0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/1cd062/00000000000000003b9b32e0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/69907d/00000000000000003b9b32e1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/69907d/00000000000000003b9b32e1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/69907d/00000000000000003b9b32e1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/47e847/00000000000000003b9b32e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/47e847/00000000000000003b9b32e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/47e847/00000000000000003b9b32e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/c66e2f/00000000000000003b9b32e2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/c66e2f/00000000000000003b9b32e2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/c66e2f/00000000000000003b9b32e2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/265864/00000000000000003b9b32e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/265864/00000000000000003b9b32e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/265864/00000000000000003b9b32e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/5d27c7/00000000000000003b9b32e5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/5d27c7/00000000000000003b9b32e5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/5d27c7/00000000000000003b9b32e5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/358303/00000000000000003b9b32e7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/358303/00000000000000003b9b32e7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('woff'),
    url('https://use.typekit.net/af/358303/00000000000000003b9b32e7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/0e2446/00000000000000003b9b32e6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/0e2446/00000000000000003b9b32e6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('woff'),
    url('https://use.typekit.net/af/0e2446/00000000000000003b9b32e6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/d5458c/00000000000000003b9b32e9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d5458c/00000000000000003b9b32e9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d5458c/00000000000000003b9b32e9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: 'brother-1816';
  src:
    url('https://use.typekit.net/af/be70b8/00000000000000003b9b32e8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/be70b8/00000000000000003b9b32e8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3')
      format('woff'),
    url('https://use.typekit.net/af/be70b8/00000000000000003b9b32e8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

.tk-brother-1816 {
  font-family: 'brother-1816', sans-serif;
}
