p-button {
  .p-button-label {
    font-size: 15px;
  }
  .p-button-link {
    padding: 0;
  }
  .p-button-outlined {
    border-width: 0.13rem;
    &:not(:disabled):hover {
      border-width: 0.13rem;
    }
  }
}
