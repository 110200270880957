:root {
  --size--font-base: 1rem;

  --size--letter-spacing-tight: 0.00625rem; // 0.1px
  --size--letter-spacing-close: 0.0125rem; // 0.2px
  --size--letter-spacing-wide: 0.0625rem; // 1px

  --size--font-weight-thin: 300;
  --size--font-weight-regular: 400;
  --size--font-weight-semi-bold: 500;
  --size--font-weight-bold: 600;
  --size--font-weight-bolder: 700;

  // Heading
  --size--font-heading-1: 3em;
  --size--lineheight-heading-1: 3.75rem;
  --size--font-heading-2: 1.75rem;
  --size--lineheight-heading-2: 2.25rem;
  --size--font-heading-3: 1.5rem;
  --size--lineheight-heading-3: 2rem;
  --size--font-heading-4: 1.25rem;
  --size--lineheight-heading-4: 1.75rem;
  --size--font-heading-5: 1rem;
  --size--lineheight-heading-5: 1.625rem;
  --size--font-heading-6: 0.875rem;
  --size--lineheight-heading-6: 1.5rem;
  --size--font-heading-7: 0.75rem;
  --size--lineheight-heading-7: 1.25rem;
  --size--font-heading-8: 0.6875rem;
  --size--lineheight-heading-8: 1.125rem;

  // Body
  --size--font-body-huge: 2rem;
  --size--lineheight-body-huge: 2.375rem;
  --size--font-body-xlarge: 1.5rem;
  --size--lineheight-body-xlarge: 2rem;
  --size--font-body-large: 1.25rem;
  --size--lineheight-body-large: 1.75rem;
  --size--font-body-regular: 1rem;
  --size--lineheight-body-regular: 1.625rem;
  --size--font-body-small: 0.875rem;
  --size--lineheight-body-small: 1.5rem;
  --size--font-body-xsmall: 0.75rem;
  --size--lineheight-body-xsmall: 1.25rem;
  --size--font-body-tiny: 0.6875rem;
  --size--lineheight-body-tiny: 1.125rem;

  // Eyebrow
  --size--font-body-eyebrow-regular: 0.8125rem;
  --size--lineheight-body-eyebrow-regular: 1.375rem;
  --size--font-body-eyebrow-small: 0.6857rem;
  --size--lineheight-body-eyebrow-small: 1.125rem;

  // Button-Link
  --size--font-interactive-button: 0.8125rem;
  --size--lineheight-interactive-button: 1.5rem;
  --size--font-interactive-text-link: 0.875rem;
  --size--lineheight-interactive-text-link: 1.5rem;

  --size--font-form-label: 0.875rem;
  --size--lineheight-form-label: 1.375rem;
}

@mixin typo--navigation-text {
  font-size: var(--size--font-body-xsmall);
  line-height: var(--size--lineheight-body-xsmall);
}

@mixin typo--navigation-text-active {
  font-size: var(--size--font-body-xsmall);
  line-height: var(--size--lineheight-body-xsmall);
  font-weight: bold !important;
}

// Body
@mixin typo--body-huge {
  font-size: var(--size--font-body-huge);
  line-height: var(--size--lineheight-body-huge);
  letter-spacing: var(--size--letter-spacing-close);
  font-weight: var(--size--font-weight-regular);
}

@mixin typo--body-xlarge {
  font-size: var(--size--font-body-xlarge);
  line-height: var(--size--lineheight-body-xlarge);
  letter-spacing: var(--size--letter-spacing-close);
  font-weight: var(--size--font-weight-regular);
}

@mixin typo--body-large {
  font-size: var(--size--font-body-large);
  line-height: var(--size--lineheight-body-large);
  letter-spacing: var(--size--letter-spacing-close);
  font-weight: var(--size--font-weight-regular);
}

@mixin typo--body-regular {
  font-size: var(--size--font-body-regular);
  line-height: var(--size--lineheight-body-regular);
  letter-spacing: var(--size--letter-spacing-close);
  font-weight: var(--size--font-weight-regular);
}

@mixin typo--body-small {
  font-size: var(--size--font-body-small);
  line-height: var(--size--lineheight-body-small);
  letter-spacing: var(--size--letter-spacing-close);
  font-weight: var(--size--font-weight-regular);
}

@mixin typo--body-xsmall {
  font-size: var(--size--font-body-xsmall);
  line-height: var(--size--lineheight-body-xsmall);
  letter-spacing: var(--size--letter-spacing-close);
  font-weight: var(--size--font-weight-regular);
}

// Eyebrow
@mixin typo--body-eyebrow-regular {
  font-size: var(--size--font-body-eyebrow-regular);
  line-height: var(--size--lineheight-body-eyebrow-regular);
  color: var(--color--text-eyebrow);
  letter-spacing: var(--size--letter-spacing-wide);
  font-weight: var(--size--font-weight-bold);
}

@mixin typo--body-eyebrow-small {
  font-size: var(--size--font-body-eyebrow-small);
  line-height: var(--size--lineheight-body-eyebrow-small);
  color: var(--color--text-eyebrow);
  letter-spacing: var(--size--letter-spacing-wide);
  font-weight: var(--size--font-weight-bold);
}

// Button-Link
@mixin typo--interactive-button {
  font-size: var(--size--font-interactive-button);
  line-height: var(--size--lineheight-interactive-button);
  letter-spacing: var(--size--letter-spacing-wide);
  font-weight: var(--size--font-weight-bold);
}

@mixin typo--interactive-text-link {
  font-size: var(--size--font-interactive-text-link);
  line-height: var(--size--lineheight-interactive-text-link);
  font-weight: var(--size--font-weight-semi-bold);
  letter-spacing: var(--size--letter-spacing-close);
  color: var(--color--text-link);
}
