@import './typography';

$cell-border-sizes:
  '' var(--size--spacing-x0-25),
  '-large' var(--size--spacing-x05);

$header-cell-fills:
  'light' var(--color--text-light) var(--color--text-dark),
  'dark' var(--color--text-dark) var(--color--text-light),
  'dark-light' var(--color--text-dark-light) var(--color--text-light),
  'tint' var(--color--page-background) var(--color--text-dark),
  'forecast' var(--color--text-tint) var(--color--text-light),
  'kolytics' var(--color--text-eyebrow) var(--color--text-dark),
  'affected' var(--color--text-ctaDisabled) var(--color--text-dark),
  'purple' var(--color--text-purple) var(--color--text-light),
  'purpleDisabled' var(--color--text-purpleDisabledWithoutTransparent)
    var(--color--text-light);

$body-cell-fills:
  'light' var(--color--text-light),
  'tint' var(--color--page-background),
  'affected' var(--color--text-ctaDisabled),
  'cta' var(--color--background-cta),
  'ctaDisabled' var(--color--background-ctaDisabled);

@mixin table--base {
  width: 100%;
  border-collapse: inherit;
  white-space: nowrap;
  thead {
    th {
      margin: 0;
      padding: 0;
      vertical-align: middle;

      // Fills
      @each $type, $fill, $color in $header-cell-fills {
        &.fill-#{$type} {
          background-color: $fill;
          color: $color;
        }
      }

      .section-header {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: var(--size--spacing-x3-25);
        font-weight: var(--size--font-weight-bolder);
        text-transform: uppercase;
        padding: var(--size--spacing-x5) 0;

        &.color-dark {
          color: var(--color--text-dark);

          .line {
            border-color: var(--color--text-dark);
          }
        }

        &.color-eyebrow {
          color: var(--color--text-eyebrow);

          .line {
            border-color: var(--color--text-eyebrow);
          }
        }

        .title {
          margin: 0 var(--size--spacing-x2-5);
        }

        .line {
          border-bottom: var(--size--spacing-x05) solid;
          flex: 1 1 auto;

          &:first-of-type {
            margin-left: var(--size--spacing-x2);
          }

          &:last-of-type {
            margin-right: var(--size--spacing-x2);
          }
        }
      }

      &:first-of-type {
        .cell-header {
          &.no-padding {
            padding-left: var(--size--spacing-x3);
          }
        }
      }

      .cell-header {
        position: relative;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        text-align: left;
        padding: var(--size--spacing-x3) var(--size--spacing-x4-5);
        font-size: var(--size--spacing-x3-5);
        font-weight: var(--size--font-weight-bolder);
        height: var(--size--spacing-x16);

        &.no-padding {
          padding: 0;
        }

        &.group-start {
          padding-left: 10px;
        }

        &:hover {
          .sort-btn {
            opacity: 1;
            visibility: visible;
            top: 0;
          }
        }

        .title {
          &:hover {
            & ~ .header-tooltip {
              opacity: 1;
              visibility: visible;
              top: var(--hoverTop);
            }
          }
        }

        .sort-btn {
          position: relative;
          padding: var(--size--spacing-x1-5);
          margin-left: var(--size--spacing-x2-5);
          border-radius: var(--size--spacing-x1);
          background-color: transparent;
          opacity: 0;
          visibility: hidden;
          top: calc(var(--size--spacing-x2-5) * -1);
          transition:
            opacity var(--duration--fast) var(--ease-in-out-back),
            visibility var(--duration--fast) var(--ease-in-out-back),
            top var(--duration--fast) var(--ease-in-out-back),
            background-color var(--duration--fast) var(--ease-in-out-back);

          &:hover {
            background-color: var(--color--background-medium);
          }

          &.active {
            opacity: 1;
            visibility: visible;
            top: 0;
          }

          ::ng-deep .wrapper--button {
            height: auto;
            padding: 0;
            border: none;
            background: none;

            klt-icon {
              margin-left: 0;
            }

            .label {
              display: none;
            }
          }
        }

        .header-tooltip {
          opacity: 0;
          visibility: hidden;
          position: fixed;
          top: var(--top);
          left: var(--left);
          color: var(--color--text-light);
          background-color: var(--color--text-dark);
          border-radius: var(--size--spacing-x1);
          padding: var(--size--spacing-x2) var(--size--spacing-x3);
          font-size: var(--size--spacing-x3-5);
          font-weight: var(--size--font-weight-regular);
          white-space: nowrap;
          width: auto;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          transform: translateX(-50%);
          z-index: 999;
          transition:
            opacity 0.2s var(--ease-in-out-back),
            visibility 0.2s var(--ease-in-out-back),
            top 0.2s var(--ease-in-out-back);

          &:after {
            content: '';
            position: absolute;
            top: calc(var(--size--spacing-x1-5) * -1);
            left: 50%;
            width: auto;
            border-left: var(--size--spacing-x1-5) solid transparent;
            border-right: var(--size--spacing-x1-5) solid transparent;
            border-bottom: var(--size--spacing-x1-5) solid
              var(--color--text-dark);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  tbody {
    tr {
      border-top: var(--size--spacing-x0-25) solid transparent;
      border-right: var(--size--spacing-x0-25) solid transparent;
      border-bottom: 0 solid transparent;
      border-left: var(--size--spacing-x0-25) solid transparent;
      &.highlight-row {
        td {
          .cell-data {
            font-weight: bold !important;
          }
        }
        td.sticky-left {
          border-bottom: var(--size--spacing-x0-25) solid
            var(--color--text-dark);
          border-top: var(--size--spacing-x0-25) solid var(--color--text-dark);
        }
        td.td-no-stick-first {
          border-bottom: var(--size--spacing-x0-25) solid
            var(--color--text-dark);
          border-top: var(--size--spacing-x0-25) solid var(--color--text-dark);
        }

        td.td-no-stick-last {
          border-bottom: var(--size--spacing-x0-25) solid
            var(--color--text-dark);
          border-top: var(--size--spacing-x0-25) solid var(--color--text-dark);
        }

        td.td-no-stick {
          border-bottom: var(--size--spacing-x0-25) solid
            var(--color--text-dark);
          border-top: var(--size--spacing-x0-25) solid var(--color--text-dark);
        }
      }
      .td-highlight-top {
        .cell-data {
          font-weight: bold !important;
        }
        border-top: var(--size--spacing-x0-25) solid var(--color--text-dark) !important;
      }
      .td-highlight-bottom {
        .cell-data {
          font-weight: bold !important;
        }
        border-bottom: var(--size--spacing-x0-25) solid var(--color--text-dark) !important;
      }

      // .td-highlight {
      //   .cell-data {
      //     font-weight: bold !important;
      //   }
      //   border-bottom: var(--size--spacing-x0-25) solid var(--color--text-dark) !important;
      //   border-top: var(--size--spacing-x0-25) solid var(--color--text-dark) !important;
      // }
      &.tr-even {
        background-color: var(--color--page-background);
      }
      td.td-spacing {
        border-bottom: var(--size--spacing-x0-25) solid
          var(--color--background-dark) !important;
      }
      td.sticky-left {
        border-bottom: var(--size--spacing-x0-25) solid transparent;
        border-top: var(--size--spacing-x0-25) solid transparent;
        border-left: var(--size--spacing-x0-25) solid transparent;
      }
      td.td-no-stick-first {
        border-bottom: var(--size--spacing-x0-25) solid transparent;
        border-top: var(--size--spacing-x0-25) solid transparent;
        border-left: var(--size--spacing-x0-25) solid transparent;
      }

      td.td-no-stick-last {
        border-bottom: var(--size--spacing-x0-25) solid transparent;
        border-top: var(--size--spacing-x0-25) solid transparent;
        border-right: var(--size--spacing-x0-25) solid transparent;
      }

      td.td-no-stick {
        border-bottom: var(--size--spacing-x0-25) solid transparent;
        border-top: var(--size--spacing-x0-25) solid transparent;
      }
      &:hover {
        cursor: pointer;

        td.sticky-left {
          border-color: var(--color--text-cta);
        }
        td.td-no-stick-first {
          border-color: var(--color--text-cta);
        }

        td.td-no-stick-last {
          border-color: var(--color--text-cta);
        }

        td.td-no-stick {
          border-color: var(--color--text-cta);
        }
      }

      td {
        margin: 0;
        padding: var(--size--spacing-x05);
        vertical-align: middle;

        // Fills
        @each $type, $fill in $body-cell-fills {
          &.fill-#{$type} {
            .cell-data {
              background-color: $fill;
            }
          }
        }

        // Borders
        @each $type, $size in $cell-border-sizes {
          // Light
          &.border-light#{$type} {
            border: $size solid var(--color--border-grey);
          }
          &.border-t-light#{$type} {
            border-top: $size solid var(--color--border-grey);
          }
          &.border-b-light#{$type} {
            border-bottom: $size solid var(--color--border-grey);
          }
          &.border-l-light#{$type} {
            border-left: $size solid var(--color--border-grey);
          }
          &.border-r-light#{$type} {
            border-right: $size solid var(--color--border-grey);
          }
          &.border-tb-light#{$type} {
            border-top: $size solid var(--color--border-grey);
            border-bottom: $size solid var(--color--border-grey);
          }
          &.border-lr-light#{$type} {
            border-left: $size solid var(--color--border-grey);
            border-right: $size solid var(--color--border-grey);
          }

          // Dark
          &.border-dark#{$type} {
            border: $size solid var(--color--text-dark);
          }
          &.border-t-dark#{$type} {
            border-top: $size solid var(--color--text-dark);
          }
          &.border-b-dark#{$type} {
            border-bottom: $size solid var(--color--text-dark);
          }
          &.border-l-dark#{$type} {
            border-left: $size solid var(--color--text-dark);
          }
          &.border-r-dark#{$type} {
            border-right: $size solid var(--color--text-dark);
          }
          &.border-tb-dark#{$type} {
            border-top: $size solid var(--color--text-dark);
            border-bottom: $size solid var(--color--text-dark);
          }
          &.border-lr-dark#{$type} {
            border-left: $size solid var(--color--text-dark);
            border-right: $size solid var(--color--text-dark);
          }
        }

        &:first-of-type {
          .cell-data {
            &.no-padding {
              padding-left: var(--size--spacing-x3);
            }
          }
        }

        &.group-start {
          padding-left: 10px;
          border-left: 1px solid #d3d3d3;
        }

        &.group-end {
          padding-right: 10px;
          border-right: 1px solid #d3d3d3;
        }

        &.group-end + .group-start {
          // padding-left: 0;
          border-left: none;
        }

        .cell-data {
          @include typo--body-small;
          // min-height: var(--size--spacing-x12);
          padding-top: var(--size--spacing-x6);
          padding-bottom: var(--size--spacing-x6);
          text-align: left;
          padding: var(--size--spacing-x3) var(--size--spacing-x4-5);
          border: var(--size--spacing-x0-25) solid transparent;
          border-radius: var(--size--spacing-x1);
          //overflow: hidden;
          //text-overflow: ellipsis;
          white-space: nowrap;
          //&:hover {
          //  border: var(--size--spacing-x0-25) solid var(--color--text-cta);
          //}

          &.no-padding {
            padding-left: 0;
            padding-right: 0;
          }

          &.color-success {
            color: var(--color--text-confirm);
          }

          &.color-warn {
            color: var(--color--text-error);
          }

          &.editable {
            border: var(--size--spacing-x05) solid var(--color--border-grey);
          }

          &.action {
            padding: var(--size--spacing-x3) var(--size--spacing-x10);
            max-height: 50px;

            &.no-padding {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

@media print {
  .section-header {
    padding: 0px !important;
  }
}
