@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

/* You can add global styles to this file, and also import other style files */
@import '../../../styles/main.scss';

.trial-expired {
  background: #ffd4d4 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}
p:empty {
  display: inline-block;
}
