@import 'styles/theme/typography';

.klt-toast-overlay {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  .klt-toast-container {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .klt-toast-content {
      pointer-events: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: var(--size--spacing-x3) var(--size--spacing-x5);
      border-radius: var(--size--spacing-x1);
      visibility: hidden;
      opacity: 0;
      transform: translateY(0);
      transition:
        visibility var(--duration--fast) var(--ease-in-back),
        opacity var(--duration--fast) var(--ease-in-back),
        transform var(--duration--hover) var(--ease-in-out-back);

      &.visible {
        visibility: visible;
        opacity: 1;
        transform: translateY(-4vh);
      }

      &.success {
        background: var(--color--background-dark);
      }

      &.error {
        background: var(--color--background-error);
      }

      .klt-toast-icon {
        margin-right: var(--size--spacing-x3);
      }

      .klt-toast-message {
        @include typo--body-small;
        margin-right: var(--size--spacing-x5);
        color: var(--color--background-light);
        font-weight: var(--size--font-weight-semi-bold);
      }

      .klt-toast-divider {
        margin-right: var(--size--spacing-x5);
        height: var(--size--spacing-x3);
        border-right: var(--size--spacing-x0-25) solid
          var(--color--border-lightFade);
      }

      .klt-toast-close {
        button {
          border: none;
          background: none;
          outline: none;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
