:root {
  // Transition Properties

  --duration--hover: 400ms;
  --duration--slow: 1000ms;
  --duration--fast: 200ms;
  --duration--rapid: 50ms;

  // Easings

  --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
  --ease-in-out-back: cubic-bezier(0.68, -0.6, 0.32, 1.6);
  --ease-in-back: cubic-bezier(0.36, 0, 0.66, -0.56);

  --transform-rotate-0: rotate(0);
  --transform-rotate-90: rotate(90deg);
  --transform-rotate-180: rotate(180deg);
}

@mixin spinner {
  display: inline-block;
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-left: var(--size--spacing-x2);
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    border: 0.1rem solid var(--color--text-link);
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color--text-link) transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
