//  Color Palette

$palette: (
  // Brand Colors
  color--dark: #1e2337,
  color--dark-light: #555a6e,
  color--light: #ffffff,
  color--cta: #4696a0,
  color--ctaDisabled: #d1e5e7,
  color--ctaHover: #f0f5f5,
  color--highlight: #e18c96,
  color--highlightDisabled: #f8e2e5,
  color--darkTint: #82879b,
  color--tint: #555a6e,
  color--lightTint: #373c50,
  color--darkGray: #beb9b4,
  color--gray: #e6e1d7,
  color--lightGray: #f2f2f0,
  color--bgGray: #fafafa,
  // Overlays
  color--lightOverlay: rgba(255, 255, 255, 0.8),
  color--darkOverlay: rgba(30, 35, 55, 0.85),
  // Corporate colours
  color--olive: #96aaaa,
  color--purple: #8c648c,
  color--purpleDisabled: rgba(140, 100, 140, 0.8),
  color--purpleDisabledWithoutTransparent: #a383a3,
  color--coral: #fa8c7d,
  color--yellow: #d2b964,
  color--blue: #41afbe,
  color--pink: #be6478,
  color--mauve: #5a5a6e,
  color--green: #5ac8b9,
  color--brown: #968c8c,
  color--confirm: #6eaf7d,
  color--error: #dc4b5f,
  color--warning: #e0ca4a,
  color--info: #50a0d2,
  color--orange: #ed8576,
  color--green-gray: #8ea1a0,

  // Retail colours
  color--retail-olive: #91b4b4,
  color--retail-purple: #824b78,
  color--retail-coral: #fa796c,
  color--retail-yellow: #e0ca4a,
  color--retail-blue: #0a9cb2,
  color--retail-pink: #d25a7d,
  color--retail-mauve: #564e65,
  color--retail-green: #12bdb0,
  color--retail-brown: #a08c87,
  color--retail-confirm: #5aaa73,
  color--retail-error: #dc4b5f,
  color--retail-warning: #e0ca4a,
  color--retail-info: #50a0d2,
  // Shadows
  color--blackShadow: rgba(30, 35, 55, 0.12),
  // Gradients
  gradient--overlay-large:
    linear-gradient(90deg, rgba(0, 0, 0, 0.75) 29%, rgba(0, 0, 0, 0) 64%)
);

@function c($color-name) {
  @return map-get($palette, $color-name);
}

:root {
  // Text Colors

  --color--text: #{c(color--dark)};
  --color--text-olive: #{c(color--olive)};
  --color--text-coral: #{c(color--coral)};
  --color--text-purple: #{c(color--purple)};
  --color--text-dark: #{c(color--dark)};
  --color--text-dark-light: #{c(color--dark-light)};
  --color--text-light: #{c(color--light)};
  --color--text-link: #{c(color--cta)};
  --color--text-eyebrow: #{c(color--highlight)};
  --color--text-lightGrey: #{c(color--lightGray)};
  --color--text-error: #{c(color--error)};
  --color--text-darkGray: #{c(color--darkGray)};
  --color--text-gray: #{c(color--gray)};
  --color--text-darkTint: #{c(color--darkTint)};
  --color--text-ctaDisabled: #{c(color--ctaDisabled)};
  --color--text-cta: #{c(color--cta)};
  --color--text-warning: #{c(color--warning)};
  --color--text-confirm: #{c(color--confirm)};
  --color--text-tint: #{c(color--tint)};
  --color--text-purpleDisabled: #{c(color--purpleDisabled)};
  --color--text-purpleDisabledWithoutTransparent: #{c(
      color--purpleDisabledWithoutTransparent
    )};
  --color--text-yellow: #{c(color--yellow)};
  --color--text-pink: #{c(color--pink)};
  --color--text-green: #{c(color--green)};

  --color--text-orange: #{c(color--orange)};
  --color--text-green-gray: #{c(color--green-gray)};

  // Toast Colors
  --color--toast-text: #{c(color--light)};
  --color--toast-separator: #{c(color--light)};
  --color--toast-background-primary: #{c(color--dark)};
  --color--toast-background-error: #{c(color--error)};

  // Icon Colors

  --color--icon-light: #{c(color--light)};
  --color--icon-cta: #{c(color--cta)};
  --color--icon-dark: #{c(color--dark)};
  --color--icon-darkGray: #{c(color--darkGray)};
  --color--icon-confirm: #{c(color--confirm)};
  --color--icon-ctaDisabled: #{c(color--ctaDisabled)};
  --color--icon-inactive: #{c(color--darkGray)};
  --color--icon-gray: #{c(color--gray)};
  --color--icon-darkTint: #{c(color--darkTint)};
  --color--icon-error: #{c(color--error)};
  --color--icon-purple: #{c(color--purple)};

  // Border Colors
  --color--border-lightGrey: #{c(color--lightGray)};
  --color--border-grey: #{c(color--gray)};
  --color--border-cta: #{c(color--cta)};
  --color--border-ctaDisabled: #{c(color--ctaDisabled)};
  --color--border-lightFade: #{c(color--lightOverlay)};
  --color--border-darkTint: #{c(color--darkTint)};

  // Background Colors
  --color--background-light: #{c(color--light)};
  --color--background-olive: #{c(color--olive)};
  --color--background-coral: #{c(color--coral)};
  --color--background-purple: #{c(color--purple)};
  --color--background-yellow: #{c(color--yellow)};
  --color--background-dark: #{c(color--dark)};
  --color--background-pink: #{c(color--pink)};
  --color--background-blue: #{c(color--blue)};
  --color--background-dark-overlay: #{c(color--darkOverlay)};
  --color--background-cta: #{c(color--cta)};
  --color--background-cta-faded: rgba(#{c(color--cta-rgb)}, 0.08);
  --color--background-ctaHover: #{c(color--ctaHover)};
  --color--background-ctaDisabled: #{c(color--ctaDisabled)};
  --color--background-lightGrey: #{c(color--lightGray)};
  --color--background-error: #{c(color--error)};
  --color--background-gray: #{c(color--gray)};
  --color--background-eyebrow: #{c(color--highlight)};
  --color--background-tint: #{c(color--tint)};

  // Layout

  --color--page-background: #{c(color--bgGray)};
  --color--page-header-backgroud: #{c(color--dark)};
  --color--page-subheader-background: #{c(color--dark)};
  --color--background-medium: #{c(color--lightTint)};
  --color--background-selected: #{c(color--ctaDisabled)};

  // Navigation

  --color--navigation-background: #{c(color--dark)};
  --color--navigation-text: #{c(color--light)};
  --color--navigation-text-active: #{c(color--cta)};
  --color--navigation-underline-background: #{c(color--cta)};

  --color--submenu-background: #{c(color--light)};
  --color--submenu-item-hover: #{c(color--ctaHover)};
  --color--submenu-border: #{c(color--lightTint)};
  --color--submenu-active: #{c(color--cta)};

  // Button

  --color--button-primary-background: #{c(color--cta)};
  --color--button-primary-text: #{c(color--light)};
  --color--button-primary-disabled: #{c(color--ctaDisabled)};

  --color--button-error-background: #{c(color--error)};
  --color--button-error-text: #{c(color--light)};
  --color--button-error-disabled: #{c(color--retail-error)};

  --color--button-secondary-background: transparent;
  --color--button-secondary-border: #{c(color--cta)};
  --color--button-secondary-text: #{c(color--cta)};
  --color--button-secondary-border-disabled: #{c(color--ctaDisabled)};
  --color--button-secondary-disabled: #{c(color--ctaHover)};

  --color--button-icon-border: #{c(color--gray)};
  --color--button-icon-border-hover: #{c(color--cta)};

  // Forms

  --color--form-border-inactive: #{c(color--gray)};
  --color--form-border-active: #{c(color--cta)};
  --color--form-border-disabled: #{c(color--gray)};
  --color--form-border-error: #{c(color--error)};

  --color--form-background-autofill: #{c(color--ctaHover)};

  --color--form-text: #{c(color--dark)};
  --color--form-text-active: #{c(color--cta)};
  --color--form-text-disabled: #{c(color--darkGray)};
  --color--form-text-error: #{c(color--error)};

  // Shadows
  --shadow--box-drag: 0px 0.125rem 0.75rem #{c(color--blackShadow)};
  --shadow--box-tiny: 0px 0.25rem 0.5rem #{c(color--blackShadow)};
  --shadow--box-small: 0px 0.25rem 0.75rem #{c(color--blackShadow)};
  --shadow--box-medium: 0px 0.375rem 1.125rem #{c(color--blackShadow)};
  --shadow--box-large: 0px 0.5rem 1.5rem #{c(color--blackShadow)};
}
