@import 'metrics';
@import 'palette';
@import 'typography';
@import 'forms';
@import 'animations';
@import 'images';
@import 'variables';
@import 'wrappers';
@import 'modals';
@import 'table';
@import 'toast';

html {
  font-size: var(--size--font-base);
}
body,
input,
textarea {
  font-family: $font-family-base;
}

::-webkit-scrollbar {
  background-color: var(--color--background-light);
  width: var(--size--spacing-x4);
  height: var(--size--spacing-x4);
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: var(--color--background-light);
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: var(--size--spacing-x4);
  border: var(--size--spacing-x1) solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

$headings: 1, 2, 3, 4, 5, 6, 7;

@each $h in $headings {
  h#{$h} {
    font-weight: var(--size--font-weight-bold);
    font-size: var(--size--font-heading-#{$h});
    line-height: var(--size--lineheight-heading-#{$h});
    letter-spacing: var(--size--letter-spacing-close);
  }
}

a {
  color: var(--color--text-link);
  font-weight: var(--size--font-weight-semi-bold);
  letter-spacing: var(--size--letter-spacing-close);
  text-decoration: none;
  &:hover {
    text-decoration-skip-ink: auto;
    text-decoration: underline;
  }
}

::ng-deep .access-locked {
  color: #ed8576 !important;
  opacity: 0.5 !important;
  // Navigation menu
  .navigation-link-label {
    color: #ed8576 !important;
  }
  .navigation-link-underline {
    background-color: #ed8576 !important;
  }
  .color-dark,
  .color-light,
  .color-cta {
    stroke: #ed8576 !important;
  }
  .icon.round.wrapper--button {
    border: var(--size--button-border-width) solid #ed8576 !important;
  }

  // Buttons
  .wrapper--button.primary {
    background-color: #ed8576 !important;
  }
  .wrapper--button.secondary {
    border: solid var(--size--button-border-width) #ed8576;
  }
  .wrapper--button.secondary .label {
    color: #ed8576 !important;
  }
  .wrapper--button.secondary:hover {
    background: #fff8f7 !important;
  }

  // Links
  .reits--action--text {
    color: #ed8576 !important;
  }
}
