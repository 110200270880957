.klt-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  .klt-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color--background-dark-overlay);
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0.2s var(--ease-in-back),
      opacity 0.2s var(--ease-in-back);

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  .klt-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: center;
    // align-items: center;
    visibility: hidden;
    opacity: 0;
    //transform: scale(.6);
    transition:
      visibility 0.2s var(--ease-in-back),
      opacity 0.2s var(--ease-in-back),
      transform 0.4s var(--ease-in-out-back);

    &.visible {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }

    .modal-m-auto {
      margin: auto;
    }
  }
}
